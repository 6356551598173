.slideIn {
    animation: slidein .5s forwards;
  }
  .slideOut {
    animation: slideout .5s forwards;
  }
  @keyframes slidein {
    from {
      left: 100vw;
    }
  
    to {
      left: 25vw;
    }
  }
  @keyframes slideout {
    from {
      left: 25vw;
    }
  
    to {
      left: 100vw;
    }
  }
.spinner48 {
    font-size: 48px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.magnifier {
    font-size: 20px;
    background-color: white;
    padding: 2px;
}
